import { Form, useActionData } from 'react-router-dom'
import { SubmitButton } from '../../../components/buttons/submit-button'
import React, { Fragment, useEffect, useState } from 'react'
import { LookupField, LookupFieldOption } from '../../../components/form-fields/lookup'
import { EvidenceAnalysis } from './report-types'
import { ApiResponse } from '../../../api'
import classNames from 'classnames'
import { Title } from '../../../components/form-fields/componentry/title'
import { Button } from '../../../components/buttons/generic-button'
import { InlineFieldError } from '../../../components/form-fields/componentry/error-message'
import { useLocalTranslation } from '../../../hooks'

type AnalysisFormProps = {
  analysis: string
  analysisStatus: LookupFieldOption
  reportId: string
  evidenceId: string
  isEditing: boolean
  isEditingAllowed: boolean
  changeEditingState: (isEditing: boolean) => void
}

export function AnalysisForm({
  analysis,
  analysisStatus,
  reportId,
  evidenceId,
  isEditing,
  isEditingAllowed,
  changeEditingState,
}: AnalysisFormProps) {
  const [analysisData, setAnalysis] = useState<string>(analysis ?? '')
  const actionData = useActionData() as ApiResponse<EvidenceAnalysis>
  const textAreaId = 'analysis_text'
  const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false)
  const t = useLocalTranslation()

  useEffect(() => {
    // The action data can be left over from a previous submission, probably due to the routing and the way this form
    // is nested in the evidence page. I don't entirely understand how useActionData decides what data it's giving me.
    // For now the hasFormBeenSubmitted condition ensures that the action data is only used when the form has been
    // submitted so that we know the data is new.
    if (hasFormBeenSubmitted && actionData) {
      if (!actionData.error) {
        // form submitted without errors so editing is done
        changeEditingState(false)
      }
    }
  }, [actionData])

  function handleAnalysisChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setAnalysis(event.target.value)
  }

  function submitAnalysis(event: React.FormEvent<HTMLFormElement>) {
    // default form submission is not prevented, we are just tracking the submit event
    setHasFormBeenSubmitted(true)
  }

  function onCancel() {
    setHasFormBeenSubmitted(false)
    changeEditingState(false)
    if (actionData && !actionData.error) {
      setAnalysis(actionData.data.analysis)
    } else {
      setAnalysis(analysis)
    }
  }

  return (
    <Form method='put' action={`/report/${reportId}/evidence/${evidenceId}/analysis`} onSubmit={submitAnalysis}>
      {isEditing && (
        <LookupField
          id='analysisStatus'
          lookupField='analysis_status'
          title='report.analysisStatus'
          required
          defaultValue={analysisStatus}
          idsToHide={['22001']} // Hide 'Not Analysed' option
          useClientTranslations={true}
          nameTranslationPrefix='report.'
          // Until the form has been submitted we don't want to show the error message. Without this the following breaks,
          // Save a valid analysis status then:
          // 1. Edit, 2. Select 'please chose an option', 3. submit, 4. error message is shown, 5. cancel, 6. edit
          // At this point the analysis status is valid (we're editing to valid original save as we cancelled our bad edit),
          // but the error message is still shown.
          hasError={hasFormBeenSubmitted && !!actionData?.error?.analysisStatus}
          errorMessages={actionData?.error?.analysisStatus}
        />
      )}
      {isEditing && <Title id={textAreaId} title='report.reason' required={true} />}
      <textarea
        id={textAreaId}
        name='analysis'
        className={classNames(
          'w-full h-40 resize-none rounded-lg text-gray-600 placeholder:text-gray-400',
          { 'border-gray-300': isEditing },
          { 'border-gray-200 bg-gray-50 ': !isEditing },
        )}
        placeholder={t('report.reasonPlaceholder') ?? ''}
        maxLength={4000}
        onChange={handleAnalysisChange}
        value={analysisData}
        readOnly={!isEditing}
        disabled={!isEditing}
      />
      {hasFormBeenSubmitted && !!actionData?.error?.analysis && (
        <InlineFieldError errorPrefix='errorMessage.' errorMessages={actionData?.error?.analysis} />
      )}
      {isEditingAllowed && (
        <div className='flex flex-row-reverse gap-x-3 px-2'>
          {isEditing && (
            <Fragment>
              <SubmitButton translationText='save' type='submit' />
              <Button translationText='cancel' type='button' onClick={onCancel} colorScheme='white' />
            </Fragment>
          )}
          {!isEditing && (
            <Button
              translationText='edit'
              type='button'
              colorScheme='light-blue'
              onClick={() => {
                setHasFormBeenSubmitted(false)
                changeEditingState(true)
              }}
            />
          )}
        </div>
      )}
    </Form>
  )
}
